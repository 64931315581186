import React, { useEffect, useState } from 'react';
import ArticleComponent from '../components/Blog/ArticleComponent';
import useBreakpoint from '../utils/useBreakpoint';
import { handlePostPath } from '../utils/blogNavigation';
import SubscriptionModal from '../components/SubscriptionModal';
import Seo from '../components/SEO';
import { navigate } from 'gatsby';
import Cookies from 'js-cookie';

const BlogPage = ({ pageContext }) => {
  const isMedium = useBreakpoint('md', false);
  const [showSubscribe, setShowSubscribe] = useState(false);
  if (!pageContext) {
    return '';
  }

  const handleSubscribe = () => {
    setShowSubscribe(true);
  };

  useEffect(() => {
    if (Cookies.get('subscribed') === 'true') {
      return;
    }
    Cookies.set('subscribed', 'true');
    const test = setTimeout(handleSubscribe, 1000);

    return () => {
      clearTimeout(test);
    };
  }, []);

  const handlePostClick = (post) => {
    const langKey = post.lang.split('-')[0];
    handlePostPath(langKey, post);
  };
  const lang = pageContext.lang;
  const seoData = pageContext.seo;
  const pageData = pageContext.pageData;

  const handleTagClick = (tag) => {
    if (lang === 'en') {
      navigate(`/blog/${tag.toLowerCase().replace(/ /g, '-')}`);
      return;
    }
    navigate(`${lang}/blog/${tag.toLowerCase().replace(/ /g, '-')}`);
  };

  return (
    <>
      <SubscriptionModal show={showSubscribe} setShow={setShowSubscribe} />
      <Seo
        title={seoData?.blog_meta_title}
        description={seoData?.blog_meta_description}
        image={seoData?.blog_meta_image?.url}
        lang={lang}
      />
      <div
        style={{
          minHeight: '100vh',
          backgroundColor: '#F5F5F5',
          padding: 32,
          paddingTop: 144,
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <div
          style={{
            width: '100%',
            maxWidth: 1088,
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            padding: '40px 0',
          }}
        >
          <ArticleComponent
            size={isMedium ? 'md' : 'lg'}
            background={pageData?.primary_article?.data?.background?.fluid}
            title={pageData?.primary_article?.data?.title}
            date={pageData?.primary_article?.data?.date}
            content={pageData?.primary_article?.data?.content}
            author={pageData?.primary_article?.data?.author?.document?.data}
            style={{ flex: 1, display: 'flex', justifyContent: 'center' }}
            tags={pageData?.primary_article?.tags}
            onBlogClick={() => handlePostClick(pageData?.primary_article)}
            onTagClick={handleTagClick}
          />
          {pageData?.secondary_articles?.map((group, index) => {
            return (
              <div
                style={{
                  display: 'flex',
                  justifyContent: isMedium ? 'center' : 'space-between',
                  width: '100%',
                  flexWrap: isMedium ? 'wrap' : 'nowrap',
                }}
                key={index}
              >
                {group.map((item, idx) => (
                  <div style={{ display: 'flex' }} key={idx}>
                    <ArticleComponent
                      size="md"
                      background={item?.data?.background?.fluid}
                      title={item?.data?.title}
                      date={item?.data?.date}
                      content={item?.data?.content}
                      author={item?.data?.author?.document?.data}
                      tags={item?.tags?.length > 0 ? item?.tags : null}
                      onBlogClick={() => handlePostClick(item)}
                      onTagClick={handleTagClick}
                    />
                    {!isMedium && idx === 0 && <div style={{ width: 8 }} />}
                  </div>
                ))}
              </div>
            );
          })}
          {pageData?.rest_articles?.map((group, index) => {
            return (
              <div
                className={
                  isMedium ? 'blog-page-secondary-med' : 'blog-page-secondary-large'
                }
                key={index}
              >
                {group.map((item, idx) => (
                  <div style={{ display: 'flex' }} key={idx}>
                    <ArticleComponent
                      size={isMedium ? 'md' : 'sm'}
                      background={item?.data?.background?.fluid}
                      title={item?.data?.title}
                      date={item?.data?.date}
                      content={item?.data?.content}
                      author={item?.data?.author?.document?.data}
                      tags={item?.tags?.length > 0 ? item?.tags : null}
                      onBlogClick={() => handlePostClick(item)}
                      onTagClick={handleTagClick}
                    />
                    {!isMedium && idx === 0 && <div style={{ width: 8 }} />}
                  </div>
                ))}
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default BlogPage;
