import { navigate } from 'gatsby';

export const handlePostPath = (langKey, article) => {
  if (langKey === 'en') {
    if (article.data.section) {
      navigate(
        `/blog/${article.data.section.toLowerCase().replace(/ /g, '-')}/${article.uid}`
      );
      return;
    } else {
      navigate(`/blog/${article.uid}`);
      return;
    }
  }
  if (article.data.section) {
    navigate(
      `/${langKey}/blog/${article.data.section.toLowerCase().replace(/ /g, '-')}/${
        article.uid
      }`
    );
    return;
  }
  navigate(`/${langKey}/blog/${article.uid}`);
};
