import React from 'react';
import Trees from '../assets/img/sub-trees.svg';
import Logo from '../assets/img/logo-mobile.svg';
import Close from '../assets/img/close-icon.svg';
import Ellipse from '../assets/img/sub-ellipse.svg';
import Loop from '../assets/img/stay-in-the-loop.svg';
import useBreakpoint from '../utils/useBreakpoint';
import Fade from 'react-reveal/Fade';
import addToMailchimp from 'gatsby-plugin-mailchimp';
import { Field, Form, Formik } from 'formik';

const SubscriptionModal = ({ show, setShow }) => {
  const isMobile = useBreakpoint('sm', false);

  const handleClose = () => {
    setShow(false);
  };
  return (
    <>
      {show && (
        <Fade>
          <div className="sub-modal-bg" onClick={handleClose} />
          <div className="sub-modal">
            <div className="sub-modal-outer-container" onClick={() => {}}>
              <div className="sub-modal-top-container" onClick={() => {}}>
                <img src={Logo} alt="" />
                <img
                  src={Close}
                  alt=""
                  onClick={handleClose}
                  className="cursor-pointer"
                />
              </div>
              <div
                style={{
                  background: `url(${Ellipse})`,
                  backgroundSize: isMobile ? 'cover' : 'contain',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center center',
                  borderRadius: isMobile && 8,
                }}
              >
                <div className="sub-modal-middle-container">
                  <img src={Loop} alt="" style={{ width: '100%' }} />
                  <div className="subtitle description">
                    Don’t allow energy leaks. Sign up to our newsletter, so you can stay
                    up to date with our content. Just drop us your email.
                  </div>
                  <Formik
                    initialValues={{ email: '' }}
                    onSubmit={(values, { resetForm }) => {
                      handleClose();
                      addToMailchimp(values.email).then(() => {
                        resetForm();
                      });
                    }}
                  >
                    {({ handleSubmit, handleReset }) => (
                      <Form
                        name="blog-sub"
                        onSubmit={handleSubmit}
                        onReset={handleReset}
                        className="sub-modal-button-container"
                      >
                        <Field
                          type="email"
                          name="email"
                          autoComplete="on"
                          className="input-container footer-text-input-2"
                          placeholder={'Enter you email'}
                        />
                        <button className="sub-button cursor-pointer" type="submit">
                          <div
                            className="body color-white"
                            style={{ fontSize: 18, fontWeight: isMobile && 600 }}
                          >
                            {'Subscribe'}
                          </div>
                        </button>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
              {!isMobile && (
                <div className="sub-modal-bottom-container">
                  <img src={Trees} alt="" style={{ width: '100%' }} />
                </div>
              )}
            </div>
          </div>
        </Fade>
      )}
    </>
  );
};

export default SubscriptionModal;
